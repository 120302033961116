import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BlogListItem from './list-item'
const { upperFirst } = require('lodash')

class BlogListing extends Component {
    constructor(props){
        super(props);

        this.tagFilterChange = this.tagFilterChange.bind(this);
    }

    tagFilterChange = event => {
        if (event.target.value !== "") {
            window.location.href = `${this.props.basePath}/tag/${event.target.value}`
        }
        else {
            window.location.href = this.props.basePath
        }
    }

    render() {
        if (this.props.data.nodes) {
            return (
                <div className="c-blog-listing">
                    <div className="container">
                        {this.props.tags ? 
                            <div className="row">
                                <div className="c-blog-listing__filter">
                                    <div className="c-blog-listing__filter-label">Filter by:</div>
                                    <div className="c-blog-listing__filter-select">
                                        <div className="c-selectbox">
                                            <select defaultValue={this.props.currentTag ? this.props.currentTag.slug : ""} onChange={this.tagFilterChange} className="c-selectbox__select">
                                                <option value="">All categories</option>
                                                {this.props.tags.map((tagItem, i) => {     
                                                    return (<option key={tagItem.slug} value={tagItem.slug}>{upperFirst(tagItem.name)}</option>)            
                                                })}
                                            </select>
                                            <svg className="c-selectbox__arrow" viewBox="0 0 24 24">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M2.217 8.502l1.435-1.435L12 15.415l8.348-8.348L21.783 8.5 12 18.284 2.217 8.501z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                        </div> : null}
                        <div className="row">
                            {this.props.data.nodes.map((post, i) => {
                                return (
                                    <BlogListItem key={i} post={post} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        
        return null
    }
}

BlogListing.defaultProps = {
    currentTag: {}
};

BlogListing.propTypes = {
    data: PropTypes.object,
    tags: PropTypes.array,
    currentTag: PropTypes.object,
    basePath: PropTypes.string
}

export default BlogListing;